.table-list {
  padding: 30px 0;
}

.table-list table {
  width: 100%;
  table-layout: fixed;
}

.table-list td,
.table-list th {
  text-align: left;
  vertical-align: top;
}


.table-list td>div,
.table-list th>div {
  padding: 15px 20px;
  background: #fff;
}

.table-list td:first-child>div,
.table-list th:first-child>div {
  border-radius: 8px 0 0 8px;
}

.table-list td:last-child>div,
.table-list th:last-child>div {
  border-radius: 0 8px 8px 0;
}

.table-list th>div {
  padding: 15px 20px;
  color: #1E1E1E;
  font-size: 16px;
  margin-bottom: 15px;
}

.table-list td>div {
  position: relative;
  min-height: 70px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: #1E1E1E;
  font-size: 16px;
}

.table-list .o-number {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #1E1E1E;
  font-size: 16px;
}

.table-list .o-number .icon {
  width: 36px;
  height: 40px;
  margin-right: 15px;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  color: var(--main-color);
  background-color: var(--main-color-tp);
  border-radius: 8px;
}

.table-list .date,
.table-list .price {
  color: #656565;
}

.table-list .badge {
  height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: #28C66F;
  font-size: 9px;
  line-height: 18px;
  border-radius: 5px;
  background-color: rgba(40, 198, 111, 0.09);
  border: solid 1px rgba(40, 198, 111, 0.11);
}

.table-list .o-number.warning .icon,
.table-list .badge.warning {
  color: #FFAB00;
  background-color: rgba(255, 171, 0, 0.09);
  border: solid 1px rgba(255, 171, 0, 0.11);
}

.table-list .o-number.danger .icon,
.table-list .badge.danger {
  color: #EC5453;
  background-color: rgba(236, 84, 83, 0.09);
  border: solid 1px rgba(236, 84, 83, 0.11);
}

.table-list .o-number.info .icon,
.table-list .badge.info {
  color: var(--main-color);
  background-color: var(--main-color-tp);
  border: solid 1px var(--main-color-light);
}

.table-list .more {
  content: '';
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  width: 10px;
  height: 30px;
  background: url('/public/imgs/icon@more.svg') no-repeat center;
  opacity: .5;  
  transform: translateY(-50%);
}