.order-chart {
  position: relative;
  padding: 30px;
  border-radius: 10px;
  background-color: #F8F8F8;
}
.order-chart .label {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.order-chart .apexcharts-legend {
  display: none!important
}
.order-chart .apexcharts-datalabels {
  display: none!important
}
.order-chart .apexcharts-tooltip {
  display: none!important;
}
.order-chart .chart-wrap {
  position: relative;
  margin: 49px 0;
}
.order-chart .chart-wrap .details {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}
.order-chart .chart-wrap .details div {
  color: #656565;
  font-size: 38px;
  font-weight: 600;
}
.order-chart .chart-wrap .details p {
  color: #656565;
  font-size: 16px;
  margin-top: 15px;
}
.order-chart .info-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
}
.order-chart .info-area .amount span {
  color: #656565;
  font-size: 16px;
}
.order-chart .info-area .amount p {
  color: #1E1E1E;
  font-size: 30px;
  font-weight: 700;
  margin-top: 15px;
}
.order-chart .info-area .stats span {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #28C66F;
  font-size: 15px;
}
.order-chart .info-area .stats span i {
  margin-right: 5px;
}
.order-chart .info-area .stats span.red {
  color: #EC5453;
}
.order-chart .info-area .stats p {
  color: #656565;
  font-size: 12px;
  margin-top: 15px;
}