.charts-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.charts-area > div:nth-child(1) {
  flex: 0 0 66.30%;
  max-width: 66.30%;
}
.charts-area > div:nth-child(2) {
  flex: 0 0 32.2%;
  max-width: 32.2%;
}