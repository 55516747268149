.d-sidebar {
  padding: 20px 0;
  padding-top: 40px;
  min-height: 100vh;
  overflow-y: scroll;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1)
}
.d-sidebar .logo {
  width: 200px;
  height: 40px;
  background-color: #C5C5C5;
  margin: 0 auto 30px;
}
.d-sidebar .navigation {

}
.d-sidebar .navigation > ul > li {

}
.d-sidebar .navigation > ul > li > a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  color: #1E1E1E;
  font-size: 16px;
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  transition: all .3s linear;
}
.d-sidebar .navigation > ul > li > a i {
  margin-right: 10px;
  font-size: 20px;
}

.d-sidebar .navigation > ul > li > a:hover {
  color: #fff;
  background: var(--main-color);
}