.add-form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.add-form-container .photos-sidebar,
.add-form-container .nav {
  flex: 0 0 290px;
  max-width: 290px;
  padding: 15px 0;
  background-color: #fff;
  border-radius: 10px;
  align-self: flex-start;
}

.add-form-container .nav li a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  line-height: 43px;
  padding-left: 20px;
  color: #1E1E1E;
  font-size: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-left: solid 2px transparent;
  transition: all .3s linear;
}

.add-form-container .nav li a i {
  margin-right: 10px;
  font-size: 22px;
}

.add-form-container .nav li.active a,
.add-form-container .nav li a:hover {
  color: var(--main-color);
  border-left-color: var(--main-color)
}

.add-form-container .fields {
  position: relative;
  flex: 0 0 calc(100% - 310px);
  max-width: calc(100% - 310px);
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
}

.add-form-container.add-category .photos-sidebar {
  flex: 0 0 400px;
  max-width: 400px;
  padding: 20px;
}

.add-form-container.add-category .fields {
  flex: 0 0 calc(100% - 420px);
  max-width: calc(100% - 420px);  
}

.add-form-container .photos-sidebar .photo-area .label,
.add-form-container .photos-sidebar .thumbnails-area .label {
  color: #656565;
  font-size: 16px;
  margin-bottom: 30px;
}

.add-form-container .photos-sidebar .photo-area .placeholder {
  width: 280px;
  height: 200px;
  margin: 0 auto;
  background: #EFEFEF;
  border-radius: 10px;
}

.add-form-container .photos-sidebar .thumbnails-area {
  margin-top: 25px;
}

.add-form-container .photos-sidebar .thumbnails-area ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.add-form-container .photos-sidebar .thumbnails-area ul li {
  position: relative;
  flex: 0 0 24%;
  max-width: 24%;
}

.add-form-container .photos-sidebar .thumbnails-area ul li .placeholder {
  width: 100%;
  height: 45px;
  margin: 0 auto;
  background: #EFEFEF;
  border-radius: 10px;  
}

.add-form-container .photos-sidebar .thumbnails-area ul li input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.add-form-container .status-field {
  position: absolute;
  right: 30px;
  top: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #656565;
  font-size: 16px;
}

.add-form-container .status-field a {
  color: #656565;
  text-decoration: none;
}

.add-form-container .status-field .status {
  height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 28px;
  color: #28C66F;
  border-radius: 20px;
  background-color: rgba(40, 198, 111, 0.09);
  border: solid 1px rgba(40, 198, 111, 0.11);
}

.add-form-container .multi-field>.col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.add-form-container .multi-field>.col>.field {
  flex: 0 0 49%;
  max-width: 49%;
}

.add-form-container .field {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  min-height: 40px;
}

.add-form-container .field .label {
  flex: 0 0 175px;
  max-width: 175px;
  color: #656565;
  font-size: 16px;
}

.add-form-container .field .field-content {
  flex: 0 0 calc(100% - 190px);
  max-width: calc(100% - 190px);
}

.add-form-container .field .field-content input[type="text"],
.add-form-container .field .field-content input[type="number"],
.add-form-container .field .field-content input[type="date"] {
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  border: solid 1px #C5C5C5;
  border-radius: 10px;
}

.add-form-container .field .rdw-editor-wrapper {
  border: solid 1px #C5C5C5;
}

.add-form-container .field .DraftEditor-editorContainer {
  padding: 20px;
}

.add-form-container .field .field-content select {
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  border: solid 1px #C5C5C5;
  border-radius: 10px;
}

.add-form-container .field .field-content .radios-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.add-form-container .field .field-content .radios-input label {
  margin-right: 30px;
  color: #1E1E1E;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.add-form-container .field .field-content .radios-input input {
  position: relative;
  top: -1px;
  width: 18px;
  height: 18px;
  margin-right: 7px;
}

.add-form-container .field .upload-avatar {
  display: inline-block;
  position: relative;
  height: 80px;
}

.add-form-container .field .upload-avatar .preview {
  width: 80px;
  height: 80px;
  background-color: #EFEFEF;
  border-radius: 100%;
}

.add-form-container .field .upload-avatar span {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 27px;
  height: 27px;
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  background-color: #28C66F;
  border-radius: 100%;
}

.add-form-container .field .upload-avatar input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.add-form-container .field .datepicker-wrap {
  position: relative;
  display: inline-block;
}

.add-form-container .field .datepicker-wrap>span {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  background: var(--main-color);
  border-radius: 0 10px 10px 0;
}

.fields.transparent,
.fields.boxes-container {
  background: transparent;
  padding: 0;
}

.fields.boxes-container .boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fields.boxes-container .boxes .item {
  position: relative;
  flex: 0 0 49%;
  max-width: 49%;
  padding: 20px;
  padding-left: 80px;
  padding-top: 40px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 40px;
}

.fields.boxes-container .boxes .icon {
  position: absolute;
  left: 20px;
  top: 30px;
  width: 37px;
  height: 37px;
  font-size: 20px;
  text-align: center;
  line-height: 37px;
  color: var(--main-color);
  background: var(--main-color-tp);
  border-radius: 10px;
}

.fields.boxes-container .boxes .label {
  color: #656565;
  font-size: 16px;
  font-weight: 600;
}

.fields.boxes-container .boxes ul {
  margin-top: 20px;
}

.fields.boxes-container .boxes li {
  display: flex;
  flex-wrap: wrap;
  color: #656565;
  font-size: 16px;
  margin-top: 15px;
}

.fields.boxes-container .boxes li span:nth-child(1) {
  flex: 0 0 120px;
  max-width: 120px;
}

.fields.branches-container {
  background: transparent;
  padding: 0;
}

.fields.branches-container .branches {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.fields.branches-container .branches .item {
  position: relative;
  flex: 0 0 33.3%;
  max-width: 33.3%;
  padding: 0 10px;
  margin-bottom: 10px;
}

.fields.branches-container .branches .item a {
  display: block;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
}

.fields.branches-container .branches .avatar {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  background: #EFEFEF;
  border-radius: 100%;
}

.fields.branches-container .branches .name {
  color: #1E1E1E;
  font-size: 18px;
}

.fields.branches-container .branches .desc {
  color: #656565;
  font-size: 12px;
  margin: 20px 0;
}

.fields.branches-container .branches .price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #1E1E1E;
  font-size: 16px;
  font-weight: 600;
}

.fields.branches-container .branches .price i {
  font-size: 22px;
  margin-right: 10px;
  font-weight: 400;
}

.fields.branches-container .branches .status {
  margin-top: 30px;
}

.fields.branches-container .branches .status span {
  display: inline-block;
  height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10px;
  line-height: 28px;
  color: #28C66F;
  border-radius: 20px;
  background-color: rgba(40, 198, 111, 0.09);
  border: solid 1px rgba(40, 198, 111, 0.11);
}


.income-area .chart .reports-card {
  background-color: #fff;
}

.income-area .cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 20px;
}
.income-area .cards .item {
  flex: 0 0 49%;
  max-width: 49%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;  
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
}
.income-area .cards .item .icon {
  flex: 0 0 94px;
  max-width: 94px;
  font-size: 40px;
  line-height: 94px;
  text-align: center;
  color: rgba(40, 198, 111, 1);
  background-color: rgba(40, 198, 111, 0.16);
  border-radius: 10px;
}
.income-area .cards .item .icon.orange {
  color: #FF9030;
  background-color: #FFE9D6;
}
.income-area .cards .item .icon.red {
  color: #EC5453;
  background-color: #FFDCD8;
}
.income-area .cards .item .details {
  flex: 0 0 calc(100% - 94px);
  max-width: calc(100% - 94px);
  padding-right: 20px;
}
.income-area .cards .item .label {
  color: #656565;
  font-size: 18px;
}
.income-area .cards .item .value {
  margin: 10px 0;
  color: #1E1E1E;
  font-size: 32px;
  font-weight: 700;
}
.income-area .cards .item .rate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #28C66F;
  font-size: 15px;
}
.income-area .cards .item .rate i {
  margin-right: 5px;
}
.income-area .cards .item .rate.red {
  color: #EC5453;
}