.last-orders {
  position: relative;
  padding: 30px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #F8F8F8;
}
.last-orders .label {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
}
.last-orders table {
  width: 100%;
}
.last-orders table th,
.last-orders table td {
  text-align: left;
}
.last-orders table th {
  color: #1E1E1E;
  font-size: 14px;
  padding-bottom: 10px;
}
.last-orders table td {
  position: relative;;
  color: #1E1E1E;
  font-size: 16px;
  padding: 15px 0;
  vertical-align: middle;
}
.last-orders table td:first-child {
  width: 250px
}
.last-orders .customer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #1E1E1E;
  text-decoration: none;
}
.last-orders .customer .avatar {
  width: 42px;
  height: 42px;
  margin-right: 10px;
  background-color: #C5C5C5;
  border-radius: 100%;
}
.last-orders table td .more {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  width: 10px;
  height: 100%;
  background: url('/public/imgs/icon@more.svg') no-repeat center;
}