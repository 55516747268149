.d-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
}

.d-header a {
  color: #1E1E1E;
  text-decoration: none;
}

.d-header a.toggle {
  font-size: 25px;
}

.d-header .user ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.d-header .user ul li {
  margin-left: 50px;
}

.d-header .user .notification {
  position: relative;
  font-size: 30px;
}

.d-header .user .notification span {
  position: absolute;
  left: 0;
  bottom: 20px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #EC5453;
  border: solid 2px #fff;
  border-radius: 100%;
}
.d-header .user .user-toggle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: right;
}
.d-header .user .user-toggle .avatar {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-left: 15px;
  background-color: #C5C5C5;
  border-radius: 100%;
}
.d-header .user .user-toggle .avatar .status {
  position: absolute;
  right: 0;
  bottom: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #28C66F;
  border: solid 2px #fff;
  border-radius: 100%;  
}
.d-header .user .user-toggle strong {
  color: #1E1E1E;
  font-size: 16px;
  font-weight: 600;
}
.d-header .user .user-toggle p {
  color: #656565;
  font-size: 14px;
  margin-top: 5px;
}