.invoice-container {
  padding: 30px;
  color: #656565;
  background-color: #fff;
  border-radius: 10px;
}

.invoice-container .head,
.invoice-container .user {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.invoice-container .head .logo {
  margin-bottom: 15px;
}

.invoice-container .head p {
  max-width: 340px;
  font-size: 16px;
  line-height: 25px;
}

.invoice-container .head .info {
  flex: 0 0 40%;
  max-width: 40%;
}

.invoice-container .head .info .number {
  text-align: right;
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 500;
}

.invoice-container .head ul {
  margin-top: 15px;
}

.invoice-container .price-summary ul li,
.invoice-container .user ul li,
.invoice-container .head ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 5px;
}

.invoice-container .price-summary ul li span:nth-child(1),
.invoice-container .user ul li span:nth-child(1),
.invoice-container .head ul li span:nth-child(1) {
  flex: 0 0 150px;
  max-width: 150px;
}

.invoice-container .user {
  margin-top: 20px;
  padding-top: 50px;
  border-top: solid 1px #C5C5C5;
  align-items: flex-end;
}

.invoice-container .user .subhead {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.invoice-container .user p {
  margin-bottom: 10px;
}

.invoice-container .user p+ul {
  margin-top: 40px;
}

.invoice-container .note strong,
.invoice-container .user p strong {
  font-weight: 600;
}

.invoice-container .price-summary ul li,
.invoice-container .user ul li {
  justify-content: flex-start;
  margin-top: 10px;
}

.invoice-container .user ul li span:nth-child(1) {
  flex: 0 0 150px;
  max-width: 150px;
}

.invoice-container .user .payment {
  flex: 0 0 450px;
  max-width: 450px;
}

.invoice-container .user .payment ul li span:nth-child(1) {
  flex: 0 0 250px;
  max-width: 250px;
}

.invoice-container table {
  width: 100%;
  margin: 50px 0;
}

.invoice-container table th,
.invoice-container table td {
  text-align: left;
  padding: 15px 0;
}

.invoice-container table th {
  font-weight: 600;
  border-bottom: solid 1px #C5C5C5;
}

.invoice-container table td:first-child {
  font-weight: 600;
}

.invoice-container .price-summary {
  width: 300px;
  margin-left: auto;
}

.invoice-container .price-summary li {
  padding-bottom: 7px;
  margin-bottom: 7px;
}

.invoice-container .price-summary li:last-child {
  padding-top: 14px;
  border-top: solid 1px #C5C5C5;
  font-weight: 600;
}

.invoice-container .note {
  padding-top: 20px;
  margin-top: 40px;
  border-top: solid 1px #C5C5C5;
}

.invoice-container .buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
}

.invoice-container .buttons a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  border-radius: 8px;
  background: #28C66F;
  transition: all .3s linear;
}

.invoice-container .buttons a i {
  font-size: 20px;
  margin-right: 10px;
}

.invoice-container .buttons li:nth-child(2) a {
  background: #63E2F1;
}