.products-list .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.products-list .items .item {
  flex: 0 0 24%;
  max-width: 24%;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;  
}

.products-list .items .item .preview {
  width: 100%;
  height: 180px;
  margin: 0 auto;
  background: #EFEFEF;
  border-radius: 10px;
  margin-bottom: 15px;
}

.products-list .items .item .name {
  font-size: 18px;
}

.products-list .items .item .desc {
  color: #656565;
  font-size: 14px;
  margin: 15px 0;
}

.products-list .items .item .price {
  font-size: 16px;
  font-weight: 600;
  color: var(--main-color)
}