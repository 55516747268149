.table-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;  
}
.table-actions .view ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #fff;
  border: solid 1px #F3F3F3;   
  border-radius: 8px;
  overflow: hidden;
}
.table-actions .view ul li {
  cursor: pointer;
  height: 40px;
  width: 50px;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  transition: all .3s linear;
}
.table-actions .types ul li.active,
.table-actions .view ul li.active {
  color: #fff;
  background: var(--main-color);
  border-radius: 8px;
}
.table-actions .types ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #fff;
  border: solid 1px #F3F3F3;   
  border-radius: 8px;
  overflow: hidden;
}
.table-actions .types ul li {
  cursor: pointer;
  height: 40px;
  color: #1E1E1E;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  transition: all .3s linear;
}
.table-actions .types {
  margin-left: 30px;
  margin-right: auto;
}
.table-actions .filters form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;  
}
.table-actions .filters form select {
  cursor: pointer;
  height: 40px;
  margin-left: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: #1E1E1E;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  transition: all .3s linear;  
  border: solid 1px #F3F3F3;   
  border-radius: 8px;
}