.page-hero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}
.page-hero .hero {
  font-size: 28px;
  font-weight: 700;
  color: #1E1E1E;
  text-transform: uppercase;
}
.page-hero form {
  position: relative;
}
.page-hero form input {
  width: 600px;
  height: 40px;
  padding-left: 20px;
  padding-right: 30px;
  color: #1E1E1E;
  font-size: 14px;
  background: #fff;
  border: solid 1px #C5C5C5;
  border-radius: 8px;
}
.page-hero form button {
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 38px;
  background: transparent;
}
.page-hero .actions {
  display: flex;
  flex-wrap: wrap;
}
.page-hero .actions a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;  
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  border-radius: 8px;
  background: var(--main-color);
  transition: all .3s linear;
}
.page-hero .actions a i {
  font-size: 20px;
  margin-right: 10px;
}
.page-hero .actions a:hover {
  background: var(--main-color-light);
}