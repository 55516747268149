.reports-card {
  position: relative;
  padding: 30px;
  border-radius: 10px;
  background-color: #F8F8F8;  
}
.reports-card * {
  font-family: 'Open Sans', sans-serif!important;
}
.reports-card .label {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.reports-card .apexcharts-toolbar {
  display: none!important
}
.reports-card .apexcharts-legend {
  display: none!important;
}
.reports-card .legends {
  position: absolute;
  right: 30px;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.reports-card .legends li {
  margin-left: 25px;
  display: flex;
  flex-wrap: wrap;  
  align-items: center;
  color: #656565;
  font-size: 14px;
}
.reports-card .legends li span {
  width: 14px;
  height: 14px;
  margin-right: 7px;
  background-color: #FFAB00;
  border-radius: 100%;
}
.reports-card .legends li:nth-child(1) span {
  background-color: #6259CE;
}
.reports-card .legends li:nth-child(2) span {
  background-color: #28C66F;
}
.reports-card .legends li:nth-child(3) span {
  background-color: #FFAB00;
}

.reports-card path {
  clip-path: inset(0% 0% -11% 0% round 8px);
}