.auth-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  padding-left: 65px;
  padding-right: 65px;
  background: url('/public/imgs/auth-bg.svg') no-repeat right center;
  background-size: cover;
}

.auth-layout .form-area {
  flex: 0 0 460px;
  max-width: 460px;
}

.auth-layout .form-area .form {
  margin-top: 40px;
  padding: 45px 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 24px rgba(141, 141, 141, 0.18);
}

.auth-layout .form-area .hero {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}

.auth-layout .form-area .field-image {
  display: block;
  margin: 0 auto 45px;
}

.auth-layout .form-area .field {
  margin-bottom: 20px;
}

.auth-layout .form-area .field input {
  height: 40px;
  width: 100%;
  padding: 0 20px;
  border: solid 1px #C5C5C5;
  border-radius: 5px;
  transition: all .3s linear;
}

.auth-layout .form-area .field input:focus {
  color: var(--main-color);
  border-color: var(--main-color)
}

.auth-layout .form-area .remember {
  position: relative;
  font-size: 14px;
  padding-left: 28px;
}

.auth-layout .form-area .remember input {
  position: absolute;
  left: 0;
  top: 50%;
  width: 18px;
  height: 18px;
  margin-top: -1px;
  transform: translateY(-50%);
  border-radius: 100%;
}

.auth-layout .form-area .remember a {
  color: var(--main-color);
  text-decoration: none;
}

.auth-layout .form-area button {
  display: block;
  width: 100%;
  margin-top: 70px;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  background: var(--main-color);
  cursor: pointer;
  transition: all .3s linear;
}

.auth-layout .form-area button:hover {
  background: var(--main-color-light);
}

.auth-layout .form-area .forgot {
  margin-top: 25px;
  text-align: center;
}

.auth-layout .form-area .forgot a {
  color: #1E1E1E;
  text-decoration: none;
  transition: all .3s linear;
}

.auth-layout .form-area .forgot a:hover {
  color: var(--main-color)
}

.auth-layout .form-area .have-account {
  margin-top: 35px;
  text-align: center;
}

.auth-layout .form-area .have-account.m {
  margin-top: 20px;
}

.auth-layout .form-area .have-account a {
  color: var(--main-color);
  text-decoration: none;
}

.auth-layout .form-area .social-media-login {
  margin-top: 40px;
}

.auth-layout .form-area .social-media-login .label {
  color: #1E1E1E;
  font-size: 12px;
  text-align: center;
  border-bottom: solid 1px #F3F3F3;
  margin-bottom: 50px;
}

.auth-layout .form-area .social-media-login .label span {
  position: relative;
  bottom: -7px;
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  background: #fff;
}

.auth-layout .form-area .social-media-login li {
  margin-top: 25px;
}

.auth-layout .form-area .social-media-login li a {
  display: block;
  height: 40px;
  color: #1E1E1E;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  line-height: 40px;
  border: solid 1px #C5C5C5;
  border-radius: 5px;
  transition: all .3s linear;
}

.auth-layout .form-area .social-media-login li a:hover {
  color: #fff;
  background: var(--main-color);
  border-color: var(--main-color);
}

.auth-layout .form-area .social-media-login li a.google {
  background: url('/public/imgs/icon@google.svg') no-repeat 15px center;
}

.auth-layout .form-area .social-media-login li a.google:hover {
  background: var(--main-color) url('/public/imgs/icon@google.svg') no-repeat 15px center;
}

.auth-layout .image {
  flex: 0 0 calc(100% - 460px);
  max-width: calc(100% - 460px);
  min-height: 100vh;
  background: url('/public/imgs/auth-img.png') no-repeat 90% center;
}

.dashboard-container {
  position: relative;
  padding-left: 330px;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: space-between;
  background-color: #FAFAFA;
  transition: all .15s linear;
}
.dashboard-container.white {
  background-color: #fff;
}

.dashboard-container .sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100vh;
  transition: all .15s linear; 
}

.dashboard-container .content {
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.dashboard-container.hide-menu {
  padding-left: 30px;
}

.dashboard-container.hide-menu .sidebar {
  transform: translateX(-100%)
}