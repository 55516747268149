.grid-list {
  padding: 30px 0;
}

.grid-list .items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px
}

.grid-list .items .item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.grid-list .items .item a {
  position: relative;
  display: block;
  padding: 20px;
  padding-top: 25px;
  color: #1E1E1E;
  padding-left: 76px;
  text-decoration: none;
  background-color: #fff;
  border-radius: 10px;
}

.grid-list .items .item a::after {
  content: '';
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 10px;
  height: 30px;
  background: url('/public/imgs/icon@more.svg') no-repeat center;
  opacity: .5;
}

.grid-list .items .item .icon {
  width: 36px;
  height: 40px;
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  color: var(--main-color);
  background-color: var(--main-color-tp);
  border-radius: 8px;
}

.grid-list .items .item .label {
  font-size: 16px;
}

.grid-list .items .item .label span {
  color: #656565;
  display: inline-block;
  margin-right: 5px;
}

.grid-list .items .item .date {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #656565;
  font-size: 12px;
}

.grid-list .items .item .bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.grid-list .items .item .bottom .price {
  color: #656565;
  font-size: 18px;
  font-weight: 600;
}

.grid-list .items .item .bottom .tags {
  display: flex;
  flex-wrap: wrap;
}

.grid-list .items .item .bottom .tags li {
  margin-left: 5px;
  height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: #28C66F;
  font-size: 9px;
  line-height: 18px;
  border-radius: 5px;
  background-color: rgba(40, 198, 111, 0.09);
  border: solid 1px rgba(40, 198, 111, 0.11);
}

.grid-list .items .item .bottom .tags li.warning {
  color: #FFAB00;
  background-color: rgba(255, 171, 0, 0.09);
  border: solid 1px rgba(255, 171, 0, 0.11);
}

.grid-list .items .item .bottom .tags li.danger {
  color: #EC5453;
  background-color: rgba(236, 84, 83, 0.09);
  border: solid 1px rgba(236, 84, 83, 0.11);
}

.grid-list .items .item .bottom .tags li.info {
  color: var(--main-color);
  background-color: var(--main-color-tp);
  border: solid 1px var(--main-color-light);
}