.order-detail-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  padding: 20px;
  overflow-y: scroll;
}

.order-detail-modal .backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3)
}

.order-detail-modal .content-area {
  position: relative;
  z-index: 101;
  width: 910px;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px;
  background: #FAFAFA;
  border-radius: 30px;
}

.order-detail-modal .content-area .buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.order-detail-modal .content-area .buttons a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  text-decoration: none;
  border-radius: 8px;
  background: var(--main-color);
  transition: all .3s linear;
}

.order-detail-modal .content-area .buttons a i {
  font-size: 20px;
  margin-right: 10px;
}

.order-detail-modal .content-area .buttons a:hover {
  background: var(--main-color-light);
}

.order-detail-modal .content-area .tracking {
  position: relative;
  padding: 30px;
  margin-top: 20px;
  background: #fff;
  border-radius: 20px;
}

.order-detail-modal .content-area .tracking .label {
  font-size: 16px;
  font-weight: 600;
}

.order-detail-modal .content-area .tracking .id {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #656565;
}

.order-detail-modal .content-area .tracking .stepper ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.order-detail-modal .content-area .tracking .stepper ul p {
  display: none;
}

.order-detail-modal .content-area .tracking .stepper ul li {
  position: relative;
  flex: 0 0 25%;
  max-width: 25%;
  text-align: center;
}

.order-detail-modal .content-area .tracking .stepper ul li::before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 12px;
  background: #C5C5C5;
}

.order-detail-modal .content-area .tracking .stepper ul li:first-child::before {
  width: 50%;
  left: auto;
  right: 0;
}

.order-detail-modal .content-area .tracking .stepper ul li:last-child::before {
  width: 50%;
}

.order-detail-modal .content-area .tracking .stepper ul li .step {
  position: relative;
  z-index: 3;
  display: inline-block;
  width: 25px;
  height: 25px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  background: #C5C5C5;
  border-radius: 100%;
  border: solid 3px #fff;
}

.order-detail-modal .content-area .tracking .stepper ul li .step i {
  display: none;
}

.order-detail-modal .content-area .tracking .stepper .subhead {
  color: #C5C5C5;
  font-size: 16px;
  margin-top: 20px;
}

.order-detail-modal .content-area .tracking .stepper p {
  color: #1E1E1E;
  font-size: 12px;
  line-height: 22px;
}

.order-detail-modal .content-area .tracking .stepper li.active p {
  display: block;
}

.order-detail-modal .content-area .tracking .stepper li.active .subhead {
  color: var(--main-color);
  margin-bottom: 10px;
}

.order-detail-modal .content-area .tracking .stepper li.active .step {
  background: var(--main-color)
}

.order-detail-modal .content-area .tracking .stepper li.active .step i {
  display: block;
}

.order-detail-modal .content-area .tracking .stepper li.active::before {
  background: var(--main-color)
}

.order-detail-modal .content-area .order {
  position: relative;
  padding: 30px;
  margin-top: 20px;
  background: #fff;
  border-radius: 20px;
}

.order-detail-modal .content-area .order .label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
}

.order-detail-modal .content-area .order .p-table {
  width: 100%;
  font-size: 16px;
  table-layout: fixed;
}

.order-detail-modal .content-area .order .p-table td {
  padding: 10px 0;
  vertical-align: middle;
}

.order-detail-modal .content-area .order .p-table td:first-child {
  width: 40%;
}

.order-detail-modal .content-area .order .p-table .name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

.order-detail-modal .content-area .order .p-table .name .img {
  width: 65px;
  height: 45px;
  margin-right: 10px;
  background: #EFEFEF;
  border-radius: 5px;
}

.order-detail-modal .content-area .order .summary {
  border-top: solid 1px #C5C5C5;
  padding-top: 40px;
  padding-left: 60%;
  margin-top: 15px;
}

.order-detail-modal .content-area .order .summary table {
  width: 100%;
  font-size: 16px;
}

.order-detail-modal .content-area .order .summary td {
  padding: 15px 0;
  text-align: right;
}

.order-detail-modal .content-area .order .summary td:first-child {
  padding-right: 50px;
}

.order-detail-modal .content-area .order .summary tr:last-child td {
  font-weight: 600;
}

.order-detail-modal .content-area .cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.order-detail-modal .content-area .col {
  flex: 0 0 49%;
  max-width: 49%;
  align-self: stretch;
}

.order-detail-modal .content-area .info-box,
.order-detail-modal .content-area .delivery-info {
  position: relative;
  padding: 30px;
  margin-top: 20px;
  background: #fff;
  border-radius: 20px;
}

.order-detail-modal .content-area .delivery-info {
  height: calc(100% - 20px);
}

.order-detail-modal .content-area .info-box .label,
.order-detail-modal .content-area .delivery-info .label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
}

.order-detail-modal .content-area .delivery-info .name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #656565;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.order-detail-modal .content-area .delivery-info .name .logo {
  width: 115px;
  height: 80px;
  margin-right: 10px;
  background: #EFEFEF;
  border-radius: 5px;
}

.order-detail-modal .content-area .info-box ul,
.order-detail-modal .content-area .delivery-info ul {
  color: #656565;
  font-size: 16px;
}

.order-detail-modal .content-area .info-box ul li,
.order-detail-modal .content-area .delivery-info ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.order-detail-modal .content-area .info-box ul li span:first-child,
.order-detail-modal .content-area .delivery-info ul li span:first-child {
  flex: 0 0 130px;
  max-width: 130px;
}

.order-detail-modal .content-area .info-box .subhead {
  color: #656565;
  font-size: 16px;
  font-weight: 500;
}

.order-detail-modal .content-area .info-box p {
  color: #656565;
  font-size: 16px;
  margin-top: 15px;
  line-height: 20px;
  margin-bottom: 20px;
}