.product-detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}

.product-detail .images {
  flex: 0 0 440px;
  max-width: 440px;
}

.product-detail .images .preview {
  width: 100%;
  height: 310px;
  margin: 0 auto;
  background: #EFEFEF;
  border-radius: 10px;
}

.product-detail .info {
  flex: 0 0 calc(100% - 470px);
  max-width: calc(100% - 470px);
}

.product-detail .properties {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 40px;
}

.product-detail .info .name {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 500;
}

.product-detail .info p {
  color: #1E1E1E;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
}

.product-detail .info .price {
  margin-top: 25px;
  color: var(--main-color);
  font-size: 24px;
  font-weight: 600;
}

.product-detail .properties .label {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}

.product-detail .properties ul li {
  color: #656565;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 15px;
}

.product-detail .properties ul li span:nth-child(1) {
  flex: 0 0 200px;
  max-width: 200px;
}

.related-products {
  margin-top: 30px;
}

.related-products .label {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}

.related-products .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}

.related-products .items .item {
  flex: 0 0 24%;
  max-width: 24%;
  text-align: center;
  text-decoration: none;
}

.related-products .items .item .preview {
  width: 100%;
  height: 180px;
  margin: 0 auto;
  background: #EFEFEF;
  border-radius: 10px;
  margin-bottom: 15px;
}

.related-products .items .item .name {
  font-size: 18px;
}

.related-products .items .item .desc {
  color: #656565;
  font-size: 14px;
  margin: 15px 0;
}

.related-products .items .item .price {
  font-size: 16px;
  font-weight: 600;;
  color: var(--main-color)
}