.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
}
.cards .item {
  flex: 0 0 32.3%;
  max-width: 32.3%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;  
  padding: 30px;
  border-radius: 10px;
  background-color: #F8F8F8;
}
.cards .item .icon {
  flex: 0 0 94px;
  max-width: 94px;
  font-size: 40px;
  line-height: 94px;
  text-align: center;
  color: rgba(40, 198, 111, 1);
  background-color: rgba(40, 198, 111, 0.16);
  border-radius: 10px;
}
.cards .item .icon.orange {
  color: #FF9030;
  background-color: #FFE9D6;
}
.cards .item .icon.red {
  color: #EC5453;
  background-color: #FFDCD8;
}
.cards .item .details {
  flex: 0 0 calc(100% - 94px);
  max-width: calc(100% - 94px);
  padding-right: 20px;
}
.cards .item .label {
  color: #656565;
  font-size: 18px;
}
.cards .item .value {
  margin: 10px 0;
  color: #1E1E1E;
  font-size: 32px;
  font-weight: 700;
}
.cards .item .rate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #28C66F;
  font-size: 15px;
}
.cards .item .rate i {
  margin-right: 5px;
}
.cards .item .rate.red {
  color: #EC5453;
}